<template>
    <div class="container mt-5">
        <img style="width: 400px" src="../assets/img/404.png">
        <h2><strong>
            Oops.
        </strong></h2>
        <h4 class='pb-4 pt-2'>
            Something has gone missing.
        </h4>

        <router-link to="/search">
            <button class='btn btn-lg border-2 border-dark btn-rectangle-yellow'>Start again</button>
        </router-link>

    </div>
</template>


<script>
export default {
	name: 'FourOFour',
    metaInfo: {
        title: 'Oops',
        titleTemplate: '%s | Chachi',
    },
}
</script>

<style scoped>
a:hover {
    text-decoration: none;
}
button {
    width: 150px;
}
</style>
